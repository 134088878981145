<template>
  <b-dropdown
    block
    variant="combo-bithan"
    :menu-class="`w-100 ${claseAdicional}`"
  >
    <template slot="button-content">
      <b-container fluid align="left">
        <b-row class="fluid">
          <b-col cols="10" class="mr-md-auto" align-self="center">
            <span :class="{
              'combo-bithan-texto': true,
              'placeholder-bithan': !(this.itemSelected || this.itemSelected.text) }">{{ textToShow }}</span>
          </b-col>
          <b-col cols="1" class="ml-md-auto" align-self="center">
            <i class="material-icons combo-bithan-dropdown-btn">play_circle</i>
          </b-col>
        </b-row>
      </b-container>

    </template>
    <b-dropdown-item class="overflow-hidden"
      v-for="(item, i) in items"
      :key="`${new Date().getTime()+i}`"
      @click.prevent="select(item)" >
      {{item.text}}
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import { BDropdown, BDropdownItem } from 'bootstrap-vue'
export default {
  components: {
    BDropdown,
    BDropdownItem
  },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    placeholder: {
      type: String,
      default: ''
    },
    initial_text: {
      type: String,
      default: ''
    },
    claseAdicional: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      itemSelected: ''
    }
  },
  computed: {
    textToShow() {
      if(this.itemSelected && this.itemSelected.text){
        return this.itemSelected.text
      }
      return this.placeholder
    }
  },
  methods: {
    select(item) {
      this.itemSelected = item
      this.$emit('change', item)
    }
  },
  watch:{
    items(val) {
      if (val && val.length>0) {
        const item = val.find(item => item.text === this.initial_text)
        if (item && item.text) {
          this.select(item)
        }
      }
    },
    initial_text(val){
      const item = this.items.find(item => item.text === val)
        if (item && item.text) {
          this.select(item)
        }
    }
  },
  mounted() {

  }
}
</script>

<style>
/* .btn-combo-bithan .dropdown-menu {
  max-height: 300px;
  overflow-y: auto;
} */
/* .btn-combo-bithan {
  border: 1px solid #D5D5D5;
  border-radius: 8px !important;
  background-color: rgba(255,255,255,0);
  padding: 1rem;
  overflow-x: auto;
}
.btn-combo-bithan::after {
  display:none;
}
.combo-bithan-texto {
  color: #000000;
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 19px;
  overflow: hidden;
}
.combo-bithan-dropdown-btn {
  color: #331A76;
  transform: rotate(90deg);
  text-align: center;
  vertical-align: middle;
}
.placeholder-bithan {
  color: rgba(0,0,0,0.4);
} */

</style>
